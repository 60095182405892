import { Union } from "../../fable-library.3.7.12/Types.js";
import { Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Modifier_IModifier$reflection, Size_ISize$reflection } from "../Common.fs.js";
import { bool_type, union_type, list_type, class_type, string_type } from "../../fable-library.3.7.12/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.7.12/MapUtil.js";
import { singleton } from "../../fable-library.3.7.12/List.js";
import { Reflection_getCaseName } from ".././Common.fs.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Size", "is-centered", "is-right", "is-boxed", "is-toggle", "is-toggle-rounded", "is-fullwidth", "CustomClass", "Props", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Tabs.Option", [], Option, () => [[["Item", Size_ISize$reflection()]], [], [], [], [], [], [], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export class Tab_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["is-active", "CustomClass", "Props", "Modifiers"];
    }
}

export function Tab_Option$reflection() {
    return union_type("Fulma.Tabs.Tab.Option", [], Tab_Option, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function tabs(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 2:
            case 3:
            case 4:
            case 5:
            case 6: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
            case 0: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            }
            case 8: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 7: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 9: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                return Common_GenericOptions__AddCaseName_1505(result, option);
            }
        }
    }, "tabs"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), singleton(react.createElement("ul", {}, ...children)));
}

export function tab(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 2: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 1: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
        }
    }), (props_1, children_1) => react.createElement("li", keyValueList(props_1, 1), ...children_1), children);
}

