import { Union } from "../../fable-library.3.7.12/Types.js";
import { lambda_type, unit_type, union_type, string_type, bool_type, list_type, class_type } from "../../fable-library.3.7.12/Reflection.js";
import { Common_parseOptions, Common_GenericOptions__AddProp_7BFEDA81, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddModifiers_5BB435D5, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions__AddProps_416C4D0B, Common_GenericOptions_Parse_9AE2F7C, Common_GenericOptions__ToReactElement_Z6D3CD4B7, Size_ISize$reflection, Modifier_IModifier$reflection } from "../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../fable-library.3.7.12/MapUtil.js";
import { some } from "../../fable-library.3.7.12/Option.js";
import { Reflection_getCaseName } from ".././Common.fs.js";
import { DOMAttr } from "../../Fable.React.7.4.3/Fable.React.Props.fs.js";

export class Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Props", "is-active", "CustomClass", "Modifiers"];
    }
}

export function Option$reflection() {
    return union_type("Fulma.Modal.Option", [], Option, () => [[["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", bool_type]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export class Close_Option extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Size", "OnClick", "Props", "CustomClass", "Modifiers"];
    }
}

export function Close_Option$reflection() {
    return union_type("Fulma.Modal.Close.Option", [], Close_Option, () => [[["Item", Size_ISize$reflection()]], [["Item", lambda_type(class_type("Browser.Types.MouseEvent", void 0, MouseEvent), unit_type)]], [["Item", list_type(class_type("Fable.React.Props.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier$reflection())]]]);
}

export function modal(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        switch (option.tag) {
            case 0: {
                return Common_GenericOptions__AddProps_416C4D0B(result, option.fields[0]);
            }
            case 2: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            }
            case 3: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, option.fields[0]);
            }
            default: {
                if (option.fields[0]) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            }
        }
    }, "modal"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

export function close(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, (result, option) => {
        let pattern_matching_result, size, cb, props, customClass, modifiers;
        if (option.tag === 1) {
            pattern_matching_result = 2;
            cb = option.fields[0];
        }
        else if (option.tag === 2) {
            pattern_matching_result = 3;
            props = option.fields[0];
        }
        else if (option.tag === 3) {
            pattern_matching_result = 4;
            customClass = option.fields[0];
        }
        else if (option.tag === 4) {
            pattern_matching_result = 5;
            modifiers = option.fields[0];
        }
        else if (option.fields[0].tag === 0) {
            pattern_matching_result = 0;
        }
        else if (option.fields[0].tag === 1) {
            pattern_matching_result = 0;
        }
        else {
            pattern_matching_result = 1;
            size = option.fields[0];
        }
        switch (pattern_matching_result) {
            case 0: {
                console.warn(some("`is-small` and `is-medium` are not valid sizes for \u0027modal close\u0027"));
                return result;
            }
            case 1: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(size));
            }
            case 2: {
                return Common_GenericOptions__AddProp_7BFEDA81(result, new DOMAttr(40, cb));
            }
            case 3: {
                return Common_GenericOptions__AddProps_416C4D0B(result, props);
            }
            case 4: {
                return Common_GenericOptions__AddClass_Z721C83C5(result, customClass);
            }
            case 5: {
                return Common_GenericOptions__AddModifiers_5BB435D5(result, modifiers);
            }
        }
    }, "modal-close"), (props_1, children_1) => react.createElement("button", keyValueList(props_1, 1), ...children_1), children);
}

export function background(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "modal-background"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function content(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "modal-content"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function Card_card(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "modal-card"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function Card_head(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "modal-card-head"), (props, children_1) => react.createElement("header", keyValueList(props, 1), ...children_1), children);
}

export function Card_foot(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "modal-card-foot"), (props, children_1) => react.createElement("footer", keyValueList(props, 1), ...children_1), children);
}

export function Card_title(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "modal-card-title"), (props, children_1) => react.createElement("div", keyValueList(props, 1), ...children_1), children);
}

export function Card_body(options, children) {
    return Common_GenericOptions__ToReactElement_Z6D3CD4B7(Common_GenericOptions_Parse_9AE2F7C(options, Common_parseOptions, "modal-card-body"), (props, children_1) => react.createElement("section", keyValueList(props, 1), ...children_1), children);
}

